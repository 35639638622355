.lists-menu{
    display: grid;
    grid-template-columns: 1fr;
    gap: 4px;
    padding: 16px;
    .list-menu-item{
        display: flex;
        position: relative;
        width: 100%;
        height: 50px;
        padding: 0 16px;
        padding-left: 54px;
        background-color: transparent;
        border-radius: 12px;
        align-items: center;
        justify-content: space-between;
        gap: 12px;
        text-decoration: none;
        outline: none;
        border: none;
        min-width: 0;
        &::after{
            content: '';
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            border-radius: 12px;
            border: 1px solid black;
            opacity: 0.08;
            pointer-events: none;
        }
        .icon{
            display: block;
            position: absolute;
            height: 30px;
            width: 30px;
            top: 50%;
            left: 16px;
            transform: translateY(-50%);
            background-color: $colour-dark;
            border-radius: 50%;
            svg{
                display: block;
                position: absolute;
                width: auto;
                height: auto;
                max-width: 14px;
                max-height: 14px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                fill: white;
            }
            &::after{
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: transparent;
                border-radius: 50%;
                border: 1px solid black;
                opacity: 0.08;
                pointer-events: none;
            }
        }
        .name{
            display: block;
            position: relative;
            font-size: 16px;
            font-weight: 400;
            color: $colour-dark;
            line-height: 1em;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 4px 0;
            
        }
        .count{
            display: block;
            position: relative;
            height: auto;
            width: auto;
            background-color: rgba(0,0,0,0.05);
            border-radius: 4px;
            span{
                display: block;
                position: relative;
                font-size: 12px;
                line-height: 1em;
                font-weight: 400;
                color: #666666;
                line-height: 1em;
                padding: 3px 4px;
            }
        }
        @media (hover: hover) {
            &:hover{
                background-color: #EFEFEF;
                &::after{
                    display: block;
                }
            }
        }
        @each $color, $color-var in (
            pink: $colour-pink,
            red: $colour-red,
            orange: $colour-orange,
            olive: $colour-olive,
            green: $colour-green,
            teal: $colour-teal,
            deep-blue: $colour-deep-blue,
            sky-blue: $colour-sky-blue,
            lilac: $colour-lilac
        ) {
            &.--#{$color} {
                .icon {
                    background-color: $color-var;
                }
                &.--active {
                    background-color: transparentize($color-var , 0.8);
                    &::after {
                        display: block;
                    }
                }
            }
        }

    }
}
.no-lists-message{
    display: block;
    position: relative;
    width: 100%;
    padding: 20px 5%;
    font-size: 14px;
    font-weight: 400;
    color: $colour-dark;
    text-align: center;

}
.add-list-button{
    display: flex;
    position: relative;
    width: 100%;
    height: 50px;
    padding: 0 16px;
    background-color: $colour-dark;
    border-radius: 100px;
    border: none;
    outline: none;
    align-items: center;
    justify-content: center;
    gap: 16px;
    svg{
        display: block;
        position: relative;
        width: auto;
        height: auto;
        max-width: 16px;
        max-height: 16px;
        fill: white;
    }
    span{
        display: block;
        position: relative;
        font-size: 16px;
        font-weight: 400;
        color: white;
        line-height: 1em;
        padding: 4px 0;
    }
}

.lists-menu-skeleton{
    display: grid;
    grid-template-columns: 1fr;
    gap: 4px;
    padding: 16px;
    .item{
        display: flex;
        position: relative;
        width: 100%;
        height: 50px;
        padding: 0 16px;
        padding-left: 54px;
        background-color: #F5F5F5;
        border-radius: 12px;
        align-items: center;
        justify-content: flex-start;
        .icon{
            display: block;
            position: absolute;
            height: 30px;
            width: 30px;
            top: 50%;
            left: 16px;
            transform: translateY(-50%);
            background-color: #dddddd;
            border-radius: 50%;
        }
        .text{
            display: block;
            position: relative;
            width: 100%;
            height: 16px;
            background-color: #dddddd;
            border-radius: 100px;
        }
    }
    .item:nth-child(1){
        .text{
            width: 80%;
        }
    }
    .item:nth-child(2){
        .text{
            width: 40%;
        }
    }
    .item:nth-child(3){
        .text{
            width: 90%;
        }
    }
}