.colour-selector{
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    .option{
        display: block;
        position: relative;
        width: 44px;
        height: 44px;
        background-color: transparent;
        border: none;
        outline: none;
        margin: 0;
        padding: 0;
        z-index: 1;
        .icon{
            display: block;
            position: absolute;
            width: 44px;
            height: 44px;
            top: calc(50% - 22px);
            left: calc(50% - 22px);
            background-color: black;
            border-radius: 50%;
            transform-origin: center;
            z-index: 1;
        }
        &::after{
            content: '';
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 4px solid $colour-dark;
            border-radius: 50%;
            opacity: 1;
            z-index: 2;
        }

        &:hover .icon{
            cursor: pointer;
            transform: scale(0.9);
        }

        @each $color, $color-var in (
            pink: $colour-pink,
            red: $colour-red,
            orange: $colour-orange,
            olive: $colour-olive,
            green: $colour-green,
            teal: $colour-teal,
            deep-blue: $colour-deep-blue,
            sky-blue: $colour-sky-blue,
            lilac: $colour-lilac
        ) {
            &.--#{$color} {
                .icon{
                    background-color: $color-var;
                }
                &::after{
                    border-color: $color-var;
                }
            }
        }

        &.--active{
            .icon{
                border: 8px solid white;
            }
            &::after{
                display: block;
            }
        }
    }
}