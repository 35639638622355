.form{
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 20px;

    .form-group{
        display: flex;
        position: relative;
        flex-direction: column;
        gap: 4px;
        &.--center{
            align-items: center;
            .help-text{
                text-align: center;
            }
            .error-text{
                text-align: center;
            }
        }
        label{
            display: block;
            font-size: 14px;
            font-weight: 700;
            color: $colour-dark;
            padding: 0 8px;
        }
        .form-input{
            display: block;
            width: 100%;
            padding: 12px 16px;
            border: 1px solid #cccccc;
            background-color: #fafafa;
            border-radius: 12px;
            font-size: 16px;
            color: $colour-dark;
            outline: none;
            box-shadow: none;
            resize: none;
            &:focus{
                border-color: #909090;
                outline: 2px solid transparentize(black, 0.9);
            }
        }

        .otp-inputs{
            display: flex;
            gap: 8px;
            max-width: 100%;
            flex-shrink: 1;
            .form-input{
                width: 100%;
                max-width: 50px;
                height: 50px;
                padding: 0;
                text-align: center;
                flex-shrink: 1;
            }
        }

        .help-text{
            display: block;
            position: relative;
            font-size: 14px;
            line-height: 1.3em;
            color: #888888;
            padding: 0px 8px;
            margin-top: 4px;
        }
        .error-text{
            display: none;
            position: relative;
            font-size: 14px;
            line-height: 1.3em;
            color: $colour-danger;
            padding: 0px 8px;
            margin-top: 4px;
            margin-bottom: 4px;
        }

        &::before{
            content: '';
            display: none;
            position: absolute;
            top: -6px;
            left: -8px;
            width: calc(100% + 16px);
            height: calc(100% + 12px);
            border-radius: 12px;
            background-color: transparentize($colour-danger, 0.9);
            z-index: -1;
        }

        &.--has-error{
            &::before{
                display: block;
            }
            label{
                color: $colour-danger;
            }
            .error-text{
                display: block;
            }
        }
    }

    @each $color, $color-var in (
        pink: $colour-pink,
        red: $colour-red,
        orange: $colour-orange,
        olive: $colour-olive,
        green: $colour-green,
        teal: $colour-teal,
        deep-blue: $colour-deep-blue,
        sky-blue: $colour-sky-blue,
        lilac: $colour-lilac
    ) {
        &.--#{$color} {
            .form-group{
                .form-input{
                    &:focus{
                        border-color: $color-var!important;
                        outline: 3px solid transparentize($color-var, 0.8)!important;
                    }
                }
            }
        }
    }
}