.add-item-container{
    display: block;
    position: relative;
    width: 100%;
    max-width: 600px;
    padding: 4px;
    margin: 0 auto;
    transition: margin-bottom 0.2s ease-in-out;
    z-index: 10;
    &::before{
        content: '';
        display: block;
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
        height: calc(100% + 20px);
        background: rgb(255,255,255);
        background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 35%);
        pointer-events: none;
    }

    .add-item-skeleton{
        display: block;
        position: relative;
        width: 100%;
        height: 60px;
        background-color: #f0f0f0;
        border: 1px solid #f0f0f0;
        border-radius: 12px;
    }

    .add-item{
        display: block;
        position: relative;
        width: 100%;
        height: 60px;
        background-color: #FAFAFA;
        border: 1px solid #EDEDED;
        border-radius: 20px;
        overflow: hidden;
    
        transition: height 0.2s ease-in-out;
    
        .add-item-cancel{
            display: block;
                position: absolute;
                width: 60px;
                height: 60px;
                top: 0;
                right: 0;
                background-color: transparent;
                border: none;
                outline: none;
                visibility: hidden;
                .icon{
                    display: block;
                    position: absolute;
                    width: 28px;
                    height: 28px;
                    top: calc(50% - 14px);
                    left: calc(50% - 14px);
    
                    background-color: #EFEFEF;
                    border: none;
                    border-radius: 50%;
                    transform-origin: center;
                    outline: none;
                    svg{
                        display: block;
                        position: absolute;
                        fill: $colour-dark;
                        height: 14px;
                        width: auto;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                    &::after{
                        content: '';
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: transparent;
                        border-radius: 50%;
                        border: 1px solid black;
                        opacity: 0.08;
                        pointer-events: none;
                    }
                }
                @media (hover: hover) {
                    &:hover .icon{
                        cursor: pointer;
                        transform: scale(1.1);
                        background-color: #E6E6E6;
                    }
                }
                &:active .icon{
                    transform: scale(1);
                }
        }
        .item-name-container{
            display: block;
            position: relative;
            width: 100%;
        }
        .item-name{
            display: block;
            position: relative;
            width: 100%;
            height: 60px;
            background-color: transparent;
            outline: none;
            border: none;
            padding: 0 16px;
            font-size: 16px;
            line-height: 1em;
            color: black;
            &::placeholder{
                color: #888888;
                opacity: 1;
            }
            &::-ms-input-placeholder{
                color: #888888;
                opacity: 1;
            }
            &::-webkit-input-placeholder{
                color: #888888;
                opacity: 1;
            }
        }
        .item-description-container{
            display: block;
            position: relative;
            width: 100%;
            &::after{
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0px;
                width: 100%;
                height: 1px;
                background-color: #EDEDED;
            }
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
            transition-delay: 0.1s;
        }
        .item-description{
            display: block;
            position: relative;
            width: 100%;
            background-color: transparent;
            outline: none;
            border: none;
            padding: 20px 16px;
            font-size: 16px;
            line-height: 1em;
            color: black;
            resize: none;
            &::placeholder{
                color: #888888;
                opacity: 1;
            }
            &::-ms-input-placeholder{
                color: #888888;
                opacity: 1;
            }
            &::-webkit-input-placeholder{
                color: #888888;
                opacity: 1;
            }
        }
    
        
    }

    .button-container{
        display: flex;
        position: relative;
        width: 100%;
        padding: 8px;
        padding-top: 0;
        gap: 8px;
        .add-item-button{
            display: flex;
            position: relative;
            flex-grow: 1;
            width: auto;
            height: 50px;
            outline: none;
            border: none;
            border-radius: 100px;
            background-color: $colour-dark;
            color: white;
            align-items: center;
            justify-content: center;
            gap: 8px;
            z-index: 1;
            transform-origin: center;

            &::after{
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: transparent;
                border-radius: 100px;
                border: 1px solid black;
                opacity: 0.08;
                pointer-events: none;
                z-index: -1;
            }
            span{
                display: block;
                position: relative;
                font-size: 16px;
                font-weight: 500;
                line-height: 1em;
                margin: 0;
                padding: 4px 0;
            }
            svg{
                display: block;
                position: relative;
                width: 20px;
                height: 20px;
                fill: white;
            
            }
            @media (hover: hover) {
                &:hover{
                    &::after{
                        background-color: black;
                    }
                }
            }
            &:active{
                transform: scale(0.98);
            }

            .button-loader {
                display: none;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: transparent;
                width: 28px;
                height: 28px;
                z-index: 2;
                svg {
                    display: block;
                    position: relative;
                    width: 100%;
                    height: 100%;
                    fill: white;
                    animation: stepped-spin 0.8s steps(8) infinite;
                }
            }
            &.--loading {
                span {
                    opacity: 0.2;
                }
                .button-loader {
                    display: block;
                }
                &::after {
                    display: none !important;
                }
            }
        }
        .add-image-button{
            display: block;
            position: relative;
            width: 50px;
            height: 50px;
            background-color: transparent;
            border: none;
            border-radius: 12px;
            outline: none;
            transform-origin: center;
            &::after{
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: transparent;
                border-radius: 12px;
                border: 1px solid black;
                opacity: 0.08;
                pointer-events: none;
                visibility: hidden;
            }
            svg{
                display: block;
                position: absolute;
                width: 20px;
                height: 20px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                fill: $colour-dark;
            
            }
            @media (hover: hover) {
                &:hover{
                    cursor: pointer;
                    background-color: #EFEFEF;
                    &::after{
                        visibility: visible;
                    }
                }
            }
            &:active{
                transform: scale(0.95);
            }
        }
    }

    &.--active{
        .add-item{
            height: 224px;
            border-bottom-left-radius: 33px;
            border-bottom-right-radius: 33px;
            .item-name-container{
                width: calc(100% - 56px);
            }
            .add-item-cancel{
                visibility: visible;
                opacity: 1;
            }
            .item-description-container{
                opacity: 1;
            }
        }
    }
    @each $color, $color-var in (
        pink: $colour-pink,
        red: $colour-red,
        orange: $colour-orange,
        olive: $colour-olive,
        green: $colour-green,
        teal: $colour-teal,
        deep-blue: $colour-deep-blue,
        sky-blue: $colour-sky-blue,
        lilac: $colour-lilac
    ) {
        &.--#{$color} {
            .add-item-button{
                background-color: $color-var;
            }
        }
    }

    &.--disabled{
        .add-item-button{
            background-color: #CCCCCC!important;
            pointer-events: none;
        }
    }

    @media (max-width: 709px){
        padding: 8px;
        position: sticky;
        top: calc(env(safe-area-inset-top) + 74px);
        left: 0;
    }
}

.--has-status {
    @media (max-width: 709px){
        .add-item-container{
            top: calc(env(safe-area-inset-top) + 114px);
            &::before{
                top: -2px;
            }
        }
    }
}