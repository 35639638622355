$colour-dark: #333333;
$colour-danger: #D04747;

$colour-pink: #CA4FA8;
$colour-red: #D04747;
$colour-orange: #C57701;
$colour-olive:#749101;
$colour-green:#3BA365;
$colour-teal:#26A3A3;
$colour-deep-blue:#236ABD;
$colour-sky-blue:#1FA8E3;
$colour-lilac:#9D62D9;


@import 'normalise';
@import 'reboot';

@keyframes stepped-spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

body{
    //overflow-x: hidden;
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings: "slnt" 0;
    p{
        margin: 0;
        padding: 0;
        font-size: 16px;
        line-height: 1.2em;
        font-weight: 400;
        margin-bottom: 16px;
        &.--center{
            text-align: center;
        }
        &.--large{
            font-size: 18px;
            line-height: 1.3em;
        }
    }

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
}

.app{
    display: block;
    position: relative;
    width: 100%;
    min-height: 100vh;
    background-color: white;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
}

.sidebar{
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 376px;
    height: 100%;
    background-color: #FAFAFA;
    border-right: 1px solid #EDEDED;
    z-index: 10;
    .sidebar-left{
        display: flex;
        position: absolute;
        width: 76px;
        height: 100%;
        top: 0;
        left: 0;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        border-right: 1px solid #EDEDED;
        padding-top: calc(env(safe-area-inset-top) + 8px);
        padding-bottom: calc(env(safe-area-inset-top) + 16px);

        .sidebar-left-top{
            display: flex;
            width: 100%;
            height: auto;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .logo{
                display: block;
                position: relative;
                width: 60px;
                height: 60px;
                background-color: transparent;
                border-radius: 50%;
                svg{
                    display: block;
                    position: absolute;
                    width: auto;
                    height: 28px;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    fill: $colour-dark;
                }
            }
            .status{
                display: block;
                position: relative;
                width: 60px;
                height: 60px;
                background-color: transparent;
                border-radius: 50%;
                svg{
                    display: block;
                    position: absolute;
                    width: auto;
                    height: 28px;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    fill: $colour-dark;
                }
                &.--error{
                    svg{
                        fill: #C57701;
                        width: auto;
                        height: 20px;
                    }
                }
                &.--connecting{
                    svg{
                        fill: #888888;
                        width: 24px;
                        height: 24px;
                        left: calc(50% - 12px);
                        top: calc(50% - 12px);
                        transform: translate(0,0);
                        //animate to pulse around
                        animation: stepped-spin 0.8s steps(8) infinite;

                    }
                
                }
            }
        }
        .user{
            display: block;
            position: relative;
            width: 44px;
            height: 44px;
            background-color: #EFEFEF;
            border-radius: 50%;
            border: none;
            outline: none;
            svg{
                display: block;
                position: absolute;
                width: auto;
                height: 18px;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                fill: $colour-dark;
            }
            &::after{
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: transparent;
                border-radius: 50%;
                border: 1px solid black;
                opacity: 0.08;
                pointer-events: none;
            }
        }
    }

    .lists{
        display: block;
        position: absolute;
        width: calc(100% - 76px);
        height: 100%;
        top: 0;
        right: 0;
        padding: 0px 0;
        padding-top: calc(env(safe-area-inset-top) + 0px);
        overflow-y: scroll;

        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }

        .header{
            display:flex;
            position: sticky;
            top: 0px;
            width: 100%;
            background: rgb(250,250,250);
            background: linear-gradient(180deg, rgba(250,250,250,1) 50%, rgba(250,250,250,0) 100%);
            align-items: center;
            justify-content: space-between;
            height: 76px;
            padding: 0 16px;
            padding-left: 32px;
            z-index: 11;
            span{
                display: block;
                font-size: 18px;
                font-weight: 700;
                color: $colour-dark;
                line-height: 1em;
                padding: 4px 0;
                //ellipsis
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            button{
                display: block;
                position: relative;
                width: 60px;
                height: 60px;
                background-color: transparent;
                border: none;
                outline: none;
                .icon{
                    display: block;
                    position: absolute;
                    width: 28px;
                    height: 28px;
                    top: calc(50% - 14px);
                    left: calc(50% - 14px);
    
                    background-color: #EFEFEF;
                    border: none;
                    border-radius: 50%;
                    transform-origin: center;
                    outline: none;
                    svg{
                        display: block;
                        position: absolute;
                        fill: $colour-dark;
                        height: 14px;
                        width: auto;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                    &::after{
                        content: '';
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: transparent;
                        border-radius: 50%;
                        border: 1px solid black;
                        opacity: 0.08;
                        pointer-events: none;
                    }
                }
                &:hover .icon{
                    cursor: pointer;
                    transform: scale(1.1);
                    background-color: #E6E6E6;
                }
                &:active .icon{
                    transform: scale(1);
                }
            }
        }
    }
}

.mobile-menu{
    display: none;
    position: relative;
    padding-top: calc(env(safe-area-inset-top) + 74px);

    &.--has-status{
        padding-top: calc(env(safe-area-inset-top) + 74px + 40px);
    }

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }

    .header{
        display:flex;
        position: fixed;
        top: 0px;
        width: 100%;
        background-color: #FAFAFA;
        border-bottom: 1px solid #EDEDED;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        height: auto;
        padding-top: calc(env(safe-area-inset-top) + 4px);
        z-index: 11;
        .header-status{
            display:flex;
            position: relative;
            width: 100%;
            align-items: center;
            justify-content: center;
            gap: 8px;
            padding: 8px;
            border-bottom: 1px solid #EDEDED;
            span{
                display: block;
                font-size: 14px;
                font-weight: 500;
                color: $colour-dark;
                line-height: 1em;
                padding: 4px 0;
            
            }
            .icon{
                display: block;
                position: relative;
                width: 20px;
                height: 20px;
                background-color: transparent;
                border-radius: 50%;
                svg{
                    display: block;
                    position: absolute;
                    width: 16px;
                    height: auto;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    fill: $colour-dark;
                }
                &.--error{
                    svg{
                        fill: #C57701;
                    }
                }
                &.--connecting{
                    svg{
                        fill: #888888;
                        width: 16px;
                        height: 16px;
                        left: calc(50% - 8px);
                        top: calc(50% - 8px);
                        transform: translate(0,0);
                        //animate to pulse around
                        animation: stepped-spin 0.8s steps(8) infinite;
                    }
                }
            }
        }
        .header-container{
            display:flex;
            position: relative;
            width: 100%;
            padding: 4px 4px;
            align-items: center;
            justify-content: space-between;
            span{
                display: block;
                font-size: 18px;
                font-weight: 700;
                color: $colour-dark;
                line-height: 1em;
            }
            button{
                display: block;
                position: relative;
                width: 60px;
                height: 60px;
                background-color: transparent;
                border: none;
                outline: none;
                .icon{
                    display: block;
                    position: absolute;
                    width: 28px;
                    height: 28px;
                    top: calc(50% - 14px);
                    left: calc(50% - 14px);
    
                    background-color: #EFEFEF;
                    border: none;
                    border-radius: 50%;
                    transform-origin: center;
                    outline: none;
                    svg{
                        display: block;
                        position: absolute;
                        fill: $colour-dark;
                        height: 14px;
                        width: auto;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                    &::after{
                        content: '';
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: transparent;
                        border-radius: 50%;
                        border: 1px solid black;
                        opacity: 0.08;
                        pointer-events: none;
                    }
                }
                //only on touch friendly devices
    
                @media (hover: hover) {
                    &:hover .icon{
                        cursor: pointer;
                        transform: scale(1.1);
                        background-color: #E6E6E6;
                    }
                }
                
                &:active .icon{
                    transform: scale(1);
                }
            }
        }
    }

    .lists-menu{
        padding: 20px 8px;
        gap: 12px;
        .list-menu-item{
            padding-left: 72px;
            .icon{
                height: 40px;
                width: 40px;
                svg{
                    max-width: 16px;
                    max-height: 16px;
                }
            }
            .name{
                font-size: 18px;
            
            }
            .count{
                
                span{
                    font-size: 16px;
                    padding: 4px 6px;
                }
            
            }
        }
    }
}

main{
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: calc(50px + env(safe-area-inset-bottom));
    //padding-top: 8px;
    padding-top: calc(env(safe-area-inset-top) + 8px);
    padding-left: 392px;
    padding-right: 16px;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }

    .header{
        display: flex;
        position: relative;
        flex-direction: column;
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
        height: 60px;
        align-items: center;
        justify-content: center;

        .header-status{
            display:none;
            position: relative;
            width: 100%;
            align-items: center;
            justify-content: center;
            gap: 8px;
            padding: 8px;
            border-bottom: 1px solid #EDEDED;
            span{
                display: block;
                font-size: 14px;
                font-weight: 500;
                color: $colour-dark;
                line-height: 1em;
                padding: 4px 0;
            
            }
            .icon{
                display: block;
                position: relative;
                width: 20px;
                height: 20px;
                background-color: transparent;
                border-radius: 50%;
                svg{
                    display: block;
                    position: absolute;
                    width: 16px;
                    height: auto;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    fill: $colour-dark;
                }
                &.--error{
                    svg{
                        fill: #C57701;
                    }
                }
                &.--connecting{
                    svg{
                        fill: #888888;
                        width: 16px;
                        height: 16px;
                        left: calc(50% - 8px);
                        top: calc(50% - 8px);
                        transform: translate(0,0);
                        //animate to pulse around
                        animation: stepped-spin 0.8s steps(8) infinite;
                    }
                }
            }
        }
        .header-container{
            display:flex;
            position: relative;
            width: 100%;
            padding: 4px 4px;
            align-items: center;
            justify-content: space-between;
            .title{
                display: flex;
                position: relative;
                align-items: center;
                gap: 12px;
                justify-content: flex-start;
                padding-left: 16px;
                min-width: 0;
                .icon{
                    display: block;
                    position: relative;
                    width: 30px;
                    height: 30px;
                    background-color: transparentize($colour-dark , 0.9);
                    border-radius: 50%;
                    flex-shrink: 0;
                    svg{
                        display: block;
                        position: absolute;
                        width: auto;
                        height: auto;
                        max-width: 14px;
                        max-height: 14px;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        fill: $colour-dark;
                    }
                }
                span{
                    display: block;
                    font-size: 18px;
                    font-weight: 700;
                    color: $colour-dark;
                    line-height: 1em;
                    //ellipsis
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    min-width: 0;
                    padding: 4px 0;
                }

                @each $color, $color-var in (
                    pink: $colour-pink,
                    red: $colour-red,
                    orange: $colour-orange,
                    olive: $colour-olive,
                    green: $colour-green,
                    teal: $colour-teal,
                    deep-blue: $colour-deep-blue,
                    sky-blue: $colour-sky-blue,
                    lilac: $colour-lilac
                ) {
                    &.--#{$color} {
                        .icon{
                            background-color: transparentize($color-var , 0.9);
                            svg{
                                fill: $color-var;
                            }
                        }
                    }
                }
            }
            .header-button{
                display: block;
                position: relative;
                width: 60px;
                height: 60px;
                background-color: transparent;
                border: none;
                outline: none;
                flex-shrink: 0;
                .icon{
                    display: block;
                    position: absolute;
                    width: 28px;
                    height: 28px;
                    top: calc(50% - 14px);
                    left: calc(50% - 14px);
    
                    background-color: #EFEFEF;
                    border: none;
                    border-radius: 50%;
                    transform-origin: center;
                    outline: none;
                    svg{
                        display: block;
                        position: absolute;
                        fill: $colour-dark;
                        height: 14px;
                        width: auto;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                    &::after{
                        content: '';
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: transparent;
                        border-radius: 50%;
                        border: 1px solid black;
                        opacity: 0.08;
                        pointer-events: none;
                    }
                }
                @media (hover: hover) {
                    &:hover .icon{
                        cursor: pointer;
                        transform: scale(1.1);
                        background-color: #E6E6E6;
                    }
                }
                &:active .icon{
                    transform: scale(1);
                }
            }
            .back-button{
                display: none;
            }
        }
    }
}

@media (max-width: 900px){
    .sidebar{
        width: 300px;
    }
    main{
        padding-left: 316px;
    
    }
}

@media (max-width: 709px){
    .mobile-menu{
        display: block;
    }
    main{
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-top: calc(env(safe-area-inset-top) + 74px);
        &.--has-status{
            padding-top: calc(env(safe-area-inset-top) + 74px + 40px);
        }
        .header{
            display:flex;
            position: fixed;
            top: 0px;
            width: 100%;
            max-width: 100%;
            background-color: #FAFAFA;
            border-bottom: 1px solid #EDEDED;
            align-items: center;
            justify-content: space-between;
            height: auto;
            padding: 0;
            padding-top: calc(env(safe-area-inset-top) + 4px);
            z-index: 20;
            .header-status{
                display: flex;
            }
            .header-container{
                .title{
                    padding: 0;
                }
                .back-button{
                    display: block;
                }
            }
            
        }
    }
    .sidebar{
        display: none;
    }
}

@import 'components/buttons';
@import 'components/context-menu';
@import 'components/form';
@import 'components/spacer';
@import 'components/modal';
@import 'components/lists-menu';
@import 'components/add-item';
@import 'components/list-items';
@import 'components/colour-selector';
@import 'components/icon-selector';
@import 'components/share-code';


@import 'darkmode';
@media (prefers-color-scheme: dark) {
    @include darkmode;
}