.spacer{
    display: flex;
    position: relative;
    width: 100%;
    height: 72px;
    align-items: center;
    justify-content: center;
    gap: 0;
    padding: 0 16px;
    .line{
        display: block;
        position: relative;
        width: 100%;
        height: 1px;
        background-color: #EDEDED;
    }
    .text{
        display: block;
        position: relative;
        font-size: 12px;
        font-weight: 400;
        color: #a5a5a5;
        padding: 0 8px;
        text-transform: uppercase;
    }
    &.--has-text{
        gap: 16px;
    }
    &.--extra-small{
        height: 12px;
    }
    &.--small{
        height: 28px;
    }
    &.--large{
        height: 120px;
    }
}