.context-menu{
    display: block;
    position: absolute;
    height: auto;
    width: auto;
    background-color: white;
    top: 8px;
    right: 8px;
    z-index: 90;
    border-radius: 16px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    transform-origin: top right;
    transform: scale(0.6);
    opacity: 0;
    visibility: hidden;
    transition: transform 0.15s, opacity 0.15s, visibility 0.15s;
    &.--active{
        transform: scale(1);
        opacity: 1;
        visibility: visible;
    }
    .context-menu-container{
        display: flex;
        position: relative;
        height: auto;
        width: auto;
        min-width: 180px;
        max-width: 300px;
        flex-direction: column;
        gap: 0px;
        padding: 8px 16px;
        .context-menu-item{
            display: flex;
            position: relative;
            height: 40px;
            width: 100%;
            align-items: center;
            gap: 12px;
            z-index: 1;
            background-color: transparent;
            border: none;
            outline: none;
            &::before{
                content: '';
                display: block;
                position: absolute;
                height: 100%;
                width: calc(100% + 16px);
                top: 0;
                left: -8px;
                background-color: black;
                opacity: 0;
                border-radius: 8px;
                z-index: -1;
            }
            @media (hover: hover) {
                &:hover{
                    &::before{
                        opacity: 0.05;
                    }
                }
            }
            &:active{
                transform: scale(0.97);
            }

            span{
                display: block;
                position: relative;
                font-size: 16px;
                font-weight: 400;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: $colour-dark;
            }
            .item-icon{
                display: block;
                position: relative;
                height: 16px;
                width: 16px;
                flex-shrink: 0;
                svg{
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    height: 100%;
                    width: 100%;
                    fill: $colour-dark;
                    transform: translate(-50%, -50%);
                }
            }
            &.--danger{
                span{
                    color: $colour-danger;
                }
                .item-icon{
                    svg{
                        fill: $colour-danger;
                    }
                }
                &::before{
                    background-color: $colour-danger;
                }
            }
        }
        .context-menu-divider{
            display: block;
            position: relative;
            height: 1px;
            margin: 4px 8px;
            width: calc(100% - 16px);
            background-color: rgba(0, 0, 0, 0.1);
        }
    }

    .close-button{
        display: none;
        position: absolute;
        width: 44px;
        height: 44px;
        top: -46px;
        right: 4px;
        background-color: transparent;
        border: none;
        outline: none;
        flex-shrink: 0;
        z-index: 2;
        opacity: 0;
        .icon{
            display: block;
            position: absolute;
            width: 28px;
            height: 28px;
            top: calc(50% - 14px);
            left: calc(50% - 14px);

            background-color: #EFEFEF;
            border: none;
            border-radius: 50%;
            transform-origin: center;
            outline: none;
            svg{
                display: block;
                position: absolute;
                fill: $colour-dark;
                height: 14px;
                width: auto;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            &::after{
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: transparent;
                border-radius: 50%;
                border: 1px solid black;
                opacity: 0.08;
                pointer-events: none;
            }
        }
        @media (hover: hover) {
            &:hover .icon{
                cursor: pointer;
                transform: scale(1.1);
                background-color: #E6E6E6;
            }
        }
        &:active .icon{
            transform: scale(1);
        }
    }

    @media (hover: none) {
        .close-button{
            display: block;
        }
        margin-top: 50px;
        .context-menu-container{
            min-width: 200px;
        }
    }
}