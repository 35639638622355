.modal-overlay{
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 100;
    opacity: 0;
    transition-duration: 0.15s;
    &.--active{
        opacity: 0.5;
    }
}
.modal-container{
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    pointer-events: none;
}

.modal{
    display: block;
    position: relative;
    width: 100%;
    max-width: 600px;
    height: auto;
    max-height: 100%;
    background-color: white;
    border-radius: 20px;
    overflow-y: scroll;
    pointer-events: all;
    opacity: 0;
    transform: translate(0, 40px);
    transition-duration: 0.15s;
    &.--active{
        opacity: 1;
        transform: translate(0, 0);
    }
    .modal-header{
        display: flex;
        position: sticky;
        top: 0;
        left: 0;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid #f0f0f0;
        height: 60px;
        padding: 0 20px;
        background-color: white;
        z-index: 10;
        .modal-title{
            display: flex;
            position: relative;
            width: 100%;
            align-items: center;
            gap:12px;
            &.--centered{
                justify-content: center;
            }
            svg{
                display: block;
                height: 20px;
                flex-shrink: 0;
                fill: $colour-dark;
            }
            h2{
                display: block;
                margin: 0;
                padding: 0;
                font-size: 18px;
                font-weight: 700;
                line-height: 1.2;
                color: $colour-dark;
                width: auto;
                min-width: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

            }
        }
        .modal-close{
            display: none;
            position: absolute;
            width: 60px;
            height: 60px;
            top: 0;
            right: 0;
            background-color: transparent;
            border: none;
            outline: none;
            .icon{
                display: block;
                position: absolute;
                width: 28px;
                height: 28px;
                top: calc(50% - 14px);
                left: calc(50% - 14px);

                background-color: #EFEFEF;
                border: none;
                border-radius: 50%;
                transform-origin: center;
                outline: none;
                svg{
                    display: block;
                    position: absolute;
                    fill: $colour-dark;
                    height: 14px;
                    width: auto;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
                &::after{
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                    border-radius: 50%;
                    border: 1px solid black;
                    opacity: 0.08;
                    pointer-events: none;
                }
            }
            @media (hover: hover) {
                &:hover .icon{
                    cursor: pointer;
                    transform: scale(1.1);
                    background-color: #E6E6E6;
                }
            }
            &:active .icon{
                transform: scale(1);
            }
        }
    }

    &.--has-close{
        .modal-header{
            padding-right: 60px;
        }
    }

    .modal-body{
        display: block;
        padding: 20px;
    }

    &.--has-close{
        .modal-header{
            .modal-close{
                display: block;
            }
        }
    }
}

@media (max-width: 709px){
    .modal-container{
        align-items: flex-end;
        padding-top: calc(env(safe-area-inset-top) + 12px);
        padding-bottom: 0;
    }
    .modal{
        box-shadow: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding-bottom: calc(env(safe-area-inset-bottom) + 12px);
    }
}