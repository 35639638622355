.list-items{
    display: grid;
    position: relative;
    grid-template-columns: 1fr;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 16px;
    gap: 8px;

    .no-items-message{
        display: block;
        position: relative;
        width: 100%;
        padding: 0 5%;
        font-size: 14px;
        font-weight: 400;
        color: #888888;
        text-align: center;
    }

    .list-item{
        display: grid;
        position: relative;
        grid-template-columns: 50px 1fr;
        padding-right: 11px;
        gap: 8px;
        &::after{
            content: '';
            display: block;
            position: absolute;
            bottom: -4px;
            left:50px;
            width: calc(100% - 50px);
            height: 1px;
            background-color: #F5F5F5;
        }
        &:last-child{
            &::after{
                display: none;
            }
        }

        .list-item-checkbox{
            display: block;
            position: relative;
            width: 50px;
            height: 50px;
            background-color: transparent;
            border:none;
            outline: none;
            padding: 0;
            margin: 0;
            transform-origin: center;
            .circle{
                display: block;
                position: absolute;
                width: 28px;
                height: 28px;
                top: calc(50% - 14px);
                left: calc(50% - 14px);
                background-color: transparent;
                border: 2px solid #CCCCCC;
                border-radius: 50%;
                visibility: visible;
                pointer-events: none;
                &::after{
                    content: '';
                    display: none;
                    position: absolute;
                    width: 18px;
                    height: 18px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: #CCCCCC;
                    border-radius: 50%;
                }
            }
            .spinner{
                display: block;
                position: absolute;
                width: 50px;
                height: 50px;
                top: 0;
                left: 0;
                visibility: hidden;
                pointer-events: none;
                svg{
                    display: block;
                    position: absolute;
                    fill: #CCCCCC;
                    width: 20px;
                    height: 20px;
                    top: calc(50% - 10px);
                    left: calc(50% - 10px);
                    animation: stepped-spin 0.8s steps(8) infinite;
                }
            
            }
            &:active{
                transform: scale(0.9);
            }
        }
        .list-item-body{
            display: flex;
            position: relative;
            width: 100%;
            min-height: 50px;
            height: auto;
            padding: 8px 0;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            background-color: transparent;
            border: none;
            outline: none;
            min-width: 0;
            color: inherit;
            .name{
                display: inline-block;
                position: relative;
                max-width: 100%;
                text-align: left;
                font-size: 16px;
                line-height: 1em;
                padding: 4px 4px;
                margin-left: -2px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                min-width: 0;
                color: $colour-dark;
                &::after{
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 10px;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background-color: $colour-dark;
                    z-index: 2;
                    transform: scaleX(0);
                    transition: transform 0.3s;
                    transform-origin: left;
                }
                &::before{
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: -2px;
                    left: -2px;
                    width: calc(100% + 4px);
                    height: calc(100% + 4px);
                    background-color: rgba(255,255,255,0.6);
                    z-index: 1;
                    opacity: 0;
                    transition: opacity 0.15s;
                }
            }
            .description{
                display: block;
                position: relative;
                width: 100%;
                text-align: left;
                font-size: 14px;
                line-height: 1.2em;
                padding: 4px;
                color: #888888;
                transition: opacity 0.15s;
            }
        }
    
        &.--active{
            .list-item-checkbox .circle{
                &::after{
                    display: block;
                }
            }
            .list-item-body{
                .name{
                    &::after{
                        transform: scaleX(1);
                    }
                    &::before{
                        opacity: 1;
                    }
                }
                .description{
                    opacity: 0.5;
                }
            }
        }
        &.--loading{
            .list-item-checkbox{
                .spinner{
                    visibility: visible;
                }
            }
        }
        &.--disabled{
            .list-item-checkbox{
                pointer-events: none;
                opacity: 0.5;
                //make black
                .circle{
                    border-color: #CCCCCC!important;
                    &::after{
                        background-color: #CCCCCC!important;
                    }
                }
            }
        }
    }

    @each $color, $color-var in (
        pink: $colour-pink,
        red: $colour-red,
        orange: $colour-orange,
        olive: $colour-olive,
        green: $colour-green,
        teal: $colour-teal,
        deep-blue: $colour-deep-blue,
        sky-blue: $colour-sky-blue,
        lilac: $colour-lilac
    ) {
        &.--#{$color} {
            .list-item.--active{
                .list-item-checkbox .circle{
                    border-color: $color-var;
                    &::after{
                        background-color: $color-var;
                    }
                }
                &.--loading{
                    .list-item-checkbox{
                        .circle::after{
                            display: none;
                        }
                        .spinner svg{
                            fill: $color-var;
                        }
                    }
                }
            }
        }
    }
}