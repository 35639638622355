@mixin darkmode {
    body{
        background-color: black;
    }
    .app{
        background-color: black;
    }
    .sidebar{
        background-color:#333333;
        border-color: #464646;
        .sidebar-left{
            border-color: #464646;
            .sidebar-left-top{
                .logo{
                    svg{
                        fill: white;
                    }
                }
            }
            .user{
                background-color: #464646;
                svg{
                    fill: white;
                }
            }
        }
        .lists{
            .header{
                background: rgb(250,250,250);
                background: linear-gradient(180deg, rgba(51,51,51,1) 50%, rgba(51,51,51,0) 100%);
                span{
                    color: white;
                }
                button{
                    .icon{
                        background-color: #464646;
                        svg{
                            fill: white;
                        }
                    }
                    &:hover .icon{
                        background-color: #505050;
                    }
                }
            }
        }
    }
    .mobile-menu{
        .header{
            background-color: #333333;
            border-color: #464646;
            .header-status{
                border-color: #464646;
                span{
                    color: white;
                
                }
            }
            .header-container{
                span{
                    color: white;
                }
                button{
                    .icon{
                        background-color: #464646;
                        svg{
                            fill: white;
                        }
                    }
                    &:hover .icon{
                        background-color: #505050;
                    }
                }
            }
        }
    }

    .lists-menu{
        .list-menu-item{
            &::after{
                border: 1px solid white;
            }
            .name{
                color: white;
            }
            .count{
                background-color: rgba(255,255,255,0.2);
                span{
                    color: white;
                }
            
            }
            &:hover{
                background-color: #464646;
            }
        }
    }
    .lists-menu-skeleton{
        .item{
            background-color: #464646;
            .icon{
                background-color: #555555;
            }
            .text{
                background-color: #555555;
            }
        }
    }
    .no-lists-message{
        color: #888888;
    }
    .add-list-button{
        background-color: white;
        span{
            color: #333333;
        }
        svg{
            fill: #333333;
        }
    }

    main{
        .header{
            .header-status{
                border-color: #464646;
                span{
                    color: white;
                
                }
            }
            .header-container{
                .title{
                    span{
                        color: white;
                    }
                    .icon{
                        background-color: transparentize($colour-dark , 0.6);
                    }
                    @each $color, $color-var in (
                        pink: $colour-pink,
                        red: $colour-red,
                        orange: $colour-orange,
                        olive: $colour-olive,
                        green: $colour-green,
                        teal: $colour-teal,
                        deep-blue: $colour-deep-blue,
                        sky-blue: $colour-sky-blue,
                        lilac: $colour-lilac
                    ) {
                        &.--#{$color} {
                            .icon{
                                background-color: transparentize($color-var , 0.6);
                                svg{
                                    fill: $color-var;
                                }
                            }
                        }
                    }
                }
                .header-button{
                    .icon{
                        background-color: #464646;
                        svg{
                            fill: white;
                        }
                    }
                    &:hover .icon{
                        background-color: #505050;
                    }
                }
            }
            @media (max-width: 709px){
                background-color: #333333;
                border-color: #464646;
            }
        }
    }

    .add-item-container{
        &::before{
            background: rgb(0,0,0);
            background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 35%);
        }
        .add-item{
            background-color: #333333;
            border-color: #464646;
            .add-item-cancel{
                .icon{
                    background-color: #464646;
                    svg{
                        fill: white;
                    }
                }
                &:hover .icon{
                    background-color: #505050;
                }
            }
            .item-name{
                color: white;
            }
            .item-description{
                color: white;
            }
            .item-description-container::after{
                background-color: #464646;
            }
            .add-image-button{
                background-color: transparent;
                svg{
                    fill: white;
                }
            
            }
        }
    }

    .list-items{
        .list-item{
            &::after{
                background-color: #333333;
            }
            .list-item-body{
                .name{
                    color: white;
                    &::after{
                        background-color: white;
                    }
                    &::before{
                        background-color: rgba(0,0,0,0.4);
                    }
                }
                .description{
                    color: #888888;
                }
            }
        }
    }

    .modal{
        background-color: #333333;
        border-color: #464646;
        .modal-header{
            background-color: #333333;
            border-color: #464646;
            .modal-title{
                svg{
                    fill: white;
                }
                h2{
                    color: white;
                }
            }
            .modal-close{
                .icon{
                    background-color: #464646;
                    svg{
                        fill: white;
                    }
                }
                &:hover .icon{
                    background-color: #505050;
                }
            }
        }
        .modal-body{
            color: white;
        }
        .modal-footer{
            background-color: #333333;
            border-color: #464646;
            button{
                background-color: #464646;
                color: white;
                &:hover{
                    background-color: #505050;
                }
            }
        }
    }

    .icon-selector{
        .option{
            .icon{
                background-color: #464646;
                svg{
                    fill: white;
                }
            }
            &:hover .icon{
                background-color: #505050;
            }
            &.--active{
                .icon{
                    background-color: white;
                    svg{
                        fill: $colour-dark;
                    }
                }
            }
        }
    }

    .colour-selector{
        .option{
            &.--active{
                .icon{
                    border: 8px solid $colour-dark;
                }
                &::after{
                    display: block;
                }
            }
        }
    }

    .button{
        background-color: #464646;
        &::after{
            border-color: white;
        }
        &::before{
            background-color: white;
        }
        .button-content{
            span{
                color: white;
            }
            svg{
                fill: white;
            }
        }
        .button-loader{
            svg{
                fill: white;
            }
        }

        &.--primary{
            background-color: white;
            .button-content{
                span{
                    color: $colour-dark;
                }
                svg{
                    fill: $colour-dark;
                }
            }
            .button-loader{
                svg{
                    fill: $colour-dark;
                }
            }
            &::before{
                background-color: black;
            }
        }
    }
    .button-text{
        &::before{
            background-color: white;
        }
        .button-content{
            span{
                color: white;
            }
            svg{
                fill: white;
            }
        }
        .button-loader{
            svg{
                fill: white!important;
            }
        }
    }

    .form{
        .form-group{
            label{
                color: white;
            }
            .form-input{
                background-color: #383838;
                border: 1px solid #505050;
                color: white;
                &:focus{
                    border-color: #c0c0c0;
                    outline: 2px solid transparentize(white, 0.9);
                }
            }
        }
    }

    .spacer{
        .line{
            background-color: #464646;
        }
        .text{
            color: #908f8f;
        }
    }

    .context-menu{
        background-color: #333333;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.1);
        .close-button{
            .icon{
                background-color: #464646;
                svg{
                    fill: white;
                }
            }
            &:hover .icon{
                background-color: #505050;
            }
        }
        .context-menu-item{
            &::before{
                background-color: white!important;
            }
            &::after{
                box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.06)!important;
            }
            span{
                color: white!important;
            }
            .item-icon{
                svg{
                    fill: white!important;
                }
            }
            &.--danger{
                span{
                    color: $colour-danger!important;
                }
                .item-icon{
                    svg{
                        fill: $colour-danger!important;
                    }
                }
            }
        }
        .context-menu-divider{
            background-color: rgba(255, 255, 255, 0.1)!important;
        }
    }

    .share-code{
        background-color: #464646;
        box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.1);
        span{
            color: white;
        }
    }
}