.button-group {
    display: flex;
    gap: 12px;
    width: 100%;
    flex-wrap: wrap;
    &.--fw {
        .button {
            flex-grow: 1;
        }
    }
    &.--stack {
        flex-direction: column;
    }
    &.--center{
        justify-content: center;
    }
    @media (max-width: 709px) {
        .button {
            flex-grow: 1;
        }
        &.--mob-no-grow{
            .button {
                flex-grow: 0;
            }
        }
    }
}

.button {
    display: inline-flex;
    position: relative;
    width: auto;
    height: 50px;
    padding: 0;
    margin: 0;
    background-color: #EFEFEF;
    border: none;
    outline: none;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    z-index: 1;

    &:focus {
        outline: none;
        box-shadow: none;
    }
    &:active {
        outline: none;
        box-shadow: none;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        border-radius: 100px;
        border: 1px solid black;
        opacity: 0.08;
        pointer-events: none;
        z-index: -1;
    }
    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        border-radius: 100px;
        opacity: 0;
        pointer-events: none;
        z-index: -1;
    }

    .button-content {
        display: flex;
        position: relative;
        width: auto;
        height: 100%;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding: 0;
        flex-shrink: 0;
        padding: 0 28px;
        z-index: 2;
        span {
            display: block;
            position: relative;
            font-size: 16px;
            font-weight: 500;
            width: auto;
            color: $colour-dark;
            flex-shrink: 0;
        }
        svg {
            display: block;
            position: relative;
            width: 18px;
            height: 18px;
            fill: $colour-dark;
        }
    }
    .button-loader {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: transparent;
        width: 28px;
        height: 28px;
        z-index: 2;
        svg {
            display: block;
            position: relative;
            width: 100%;
            height: 100%;
            fill: $colour-dark;
            animation: stepped-spin 0.8s steps(8) infinite;
        }
    }

    &.--loading {
        .button-content {
            opacity: 0.2;
        }
        .button-loader {
            display: block;
        }
        &::before {
            display: none !important;
        }
    }

    &:disabled {
        pointer-events: none;
        .button-content {
            opacity: 0.5;
        }
    }

    @each $color, $color-var in (
        pink: $colour-pink,
        red: $colour-red,
        orange: $colour-orange,
        olive: $colour-olive,
        green: $colour-green,
        teal: $colour-teal,
        deep-blue: $colour-deep-blue,
        sky-blue: $colour-sky-blue,
        lilac: $colour-lilac
    ) {
        &.--#{$color} {
            background-color: transparentize($color-var , 0.8);

            &.--primary {
                background-color: $color-var;
                .button-content {
                    span {
                        color: white;
                    }
                    svg {
                        fill: white;
                    }
                }
                .button-loader {
                    svg {
                        fill: white;
                    }
                }
                &::before {
                    background-color: black;
                }
            }
        }
    }

    &.--primary {
        background-color: $colour-dark;
        .button-content {
            span {
                color: white;
            }
            svg {
                fill: white;
            }
        }
        .button-loader {
            svg {
                fill: white;
            }
        }
        &::before {
            background-color: white;
        }
    }

    &.--danger {
        background-color: $colour-danger;
        .button-content {
            span {
                color: white;
            }
            svg {
                fill: white;
            }
        }
        .button-loader {
            svg {
                fill: white;
            }
        }
        &::before {
            background-color: white;
        }
    }

    @media (hover: hover) {
        &:hover {
            &::before {
                opacity: 0.05;
            }
        }
    }

    &:active {
        transform: scale(0.95);
    }

    &:disabled {
        @media (hover: hover) {
            &:hover {
                &::before {
                    opacity: 0;
                }
            }
        }
        &:active {
            transform: none;
        }
    }
}
.button-text{
    display: inline-flex;
    position: relative;
    width: auto;
    height: 50px;
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none;
    outline: none;
    border-radius: 100px;
    align-items: center;
    justify-content: center;
    z-index: 1;
    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        border-radius: 100px;
        opacity: 0;
        pointer-events: none;
        z-index: -1;
    }

    .button-content {
        display: flex;
        position: relative;
        width: auto;
        height: 100%;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding: 0;
        flex-shrink: 0;
        padding: 0 28px;
        z-index: 2;
        span {
            display: block;
            position: relative;
            font-size: 16px;
            font-weight: 500;
            width: auto;
            color: $colour-dark;
            flex-shrink: 0;
        }
        svg {
            display: block;
            position: relative;
            width: 18px;
            height: 18px;
            fill: $colour-dark;
        }
    }
    .button-loader {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: transparent;
        width: 28px;
        height: 28px;
        z-index: 2;
        svg {
            display: block;
            position: relative;
            width: 100%;
            height: 100%;
            fill: $colour-dark;
            animation: stepped-spin 0.8s steps(8) infinite;
        }
    }

    &.--loading {
        .button-content {
            opacity: 0.2;
        }
        .button-loader {
            display: block;
        }
        &::before {
            display: none !important;
        }
    }

    &:disabled {
        pointer-events: none;
        .button-content {
            opacity: 0.5;
        }
    }

    &.--danger {
        .button-content {
            span {
                color: $colour-danger;
            }
            svg {
                fill: $colour-danger;
            }
        }
        .button-loader {
            svg {
                fill: $colour-dark;
            }
        }
    }

    @media (hover: hover) {
        &:hover {
            &::before {
                opacity: 0.05;
            }
        }
    }

    &:active {
        transform: scale(0.95);
    }

    &:disabled {
        @media (hover: hover) {
            &:hover {
                &::before {
                    opacity: 0;
                }
            }
        }
        &:active {
            transform: none;
        }
    }
}
