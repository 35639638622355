.icon-selector{
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    .option{
        display: block;
        position: relative;
        width: 44px;
        height: 44px;
        background-color: transparent;
        border: none;
        outline: none;
        margin: 0;
        padding: 0;
        z-index: 1;
        .icon{
            display: block;
            position: absolute;
            width: 44px;
            height: 44px;
            top: calc(50% - 22px);
            left: calc(50% - 22px);
            background-color: #EFEFEF;
            border-radius: 50%;
            transform-origin: center;
            z-index: 1;

            svg{
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 24px;
                max-height: 24px;
                width: auto;
                height: auto;
                fill: $colour-dark;
            }
        }

        &:hover .icon{
            cursor: pointer;
            transform: scale(0.9);
        }

        &.--active{
            .icon{
                background-color: $colour-dark;
                svg{
                    fill: white;
                }
            }
        }
    }
}