.share-code{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    background-color: #f5f5f5;
    border-radius: 20px;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    gap: 10px;
    span{
        display: block;
        font-family: "JetBrains Mono", monospace;
        font-optical-sizing: auto;
        font-weight: 700;
        font-style: normal;
        font-size: 36px;
        line-height: 1em;
        color: $colour-dark;
    }
}